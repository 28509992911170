// src/views/Login.vue

<template>
  <div>
    <h1>Porzućcie wszelką nadzieję, wy,<br/>którzy tu wchodzicie ...</h1>
    <input type="text" placeholder="nazwa użytkownika" v-model="username" />
    <input type="password" placeholder="hasło" v-model="password" />
    <input type="button" @click="login" value="Login" />
    <p v-if="msg">{{ msg }}</p>
    
  </div>
</template>
<script>
import AuthService from '@/services/AuthService.js';

export default {
  data() {
    return {
      username: '',
      password: '',
      msg: ''
    };
  },
  methods: {
    async login() {
      try {
        const credentials = {
          username: this.username,
          password: this.password
        };
        const response = await AuthService.login(credentials).catch( error => { this.msg = error; })
        this.msg = response.msg;

        const token = response.token;
        const user = response.user;
        if( token != undefined ) {
          this.$store.dispatch('login', { token, user });
          this.$router.push('/quantities');
        }
      } catch (error) {
        console.log(error);
        this.msg = error.response.data.msg;
      }
    }
  }
};
</script>

<style scoped>
div { text-align: center; }
</style>