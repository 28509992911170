<template>
    <div align="center">
		<div v-if="!iserror">
			<h3>Wyszukiwanie towarów</h3>			
			<div>
				<div>
					<div>						
						<div class="query">
							Wprowadź poszukiwany ciąg znaków
							<input type="text"  placeholder="Wprowadź poszukiwane dane" v-model="srch_pattern" />
                            <input type="button" value="Szukaj" @click="fetchData" />
						</div>						
					</div>
				</div>
				<div>
					<div class="tablecontainer">
						<table >
							<tr>
								<th>Symbol</th>
								<th>Nazwa</th>
								<th>Dodatkowy opis</th>
								<th>Jedn.<br/>miary</th>
								<th>Stan mag. główny<br/>(konsygnata)</th>
							</tr>
							<tr v-for="row in quantity_rows" v-bind:key="row.symbol">
								<td>{{ row.symbol }}</td>
								<td>{{ row.name }}</td>
								<td>{{ row.description }}</td>
								<td>{{ row.unit_of_measure }}</td>
								<td class="quantity" v-if="row.consignment_quantity">{{ row.main_warehouse_quantity }} <br/> ({{ row.consignment_quantity }})</td>
								<td class="quantity" v-else>{{ row.main_warehouse_quantity }}</td>								

							</tr>
							<tr v-if="nodata">
								<td colspan="5" align="center">Nie znaleziono żadnych towarów</td>
							</tr>
						</table>
					</div>
				</div>
			</div>  
		</div>
		<div v-if="iserror">
			{{msg}}<br/>{{error}}<br/><input type="button" value="Zaloguj ponownie" @click="gotoLogin" />
		</div>  
    </div>
</template>

<script>
import AuthService from '@/services/AuthService.js';

export default {
  data() {
    return {
      srch_pattern: '',  
      quantity_rows: [],  
      nodata: true,
      iserror: false,
      error: '',
      msg: ''
    };
  },
  async created() {
    if (!this.$store.getters.isLoggedIn) {
      this.$router.push('/login');
    }
	this.fetchData();    
  },
  methods: {
    async fetchData() {
		const response = await AuthService.getQuantieties(this.srch_pattern);			
        this.msg = response.msg;
		if( response.error == 'None' ) {
			if( response.res.length > 0 ) {
				this.quantity_rows = response.res;
				this.nodata = false;
			}
			else {
				this.quantity_rows = '';
				this.nodata = true;
			}
		}
		else {
			this.error = response.error;
			this.iserror = true;
		}
    },  
	gotoLogin() {
		this.$router.push('/login');
	},
    logout() {
      this.$store.dispatch('logout');
      this.$router.push('/login');
    }
  }
};
</script>

<style scoped>
.query {  padding: 8px; }
td.quantity { text-align: right;}
tr { padding: 8px; vertical-align: top; text-align: left;}
tr:nth-child(odd) { background-color: #f0f0f0; }
th   { border: 1px solid #ddd; background-color: #c0c0c0; }


</style>