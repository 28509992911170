import axios from 'axios';
const url = 'http://stany.grupafachowiec.net/api/';
// const url = '/api/';
export default {
  login(credentials) {
    return axios
      .post(url + 'login.php', credentials)
      .then(response => response.data)
      .catch( (reason) => { console.log(reason); })
  },
  
  getQuantieties(srch_pattern) {
    return axios
      .post(url +'actions.php', {action: "quantities", search_str: srch_pattern})
      .then( response => response.data );
  }
};